"use strict";

(function ($, window, document) {
  ("use strict"); // Theme vars.

  var $window = $(window),
    $body = $("body"),
    $html = $("html"),
    $wpAdminBar = $("#wpadminbar"),
    $slideMenu = $(".slide-out-area"),
    $headerOuterEl = $(".site-header"),
    $mainContainer = $("#container"),
    $topMenu = $(".top-menu"),
    $menu = $(".hamburger-menu"),
    $announceBar = $(".announcement-bar");
  /**
   * Nectar DOM info helper.
   *
   * @since 9.0
   */

  var afreshDOMInfo = {
    usingMobileBrowser: navigator.userAgent.match(
      /(Android|iPod|iPhone|iPad|BlackBerry|IEMobile|Opera Mini)/
    )
      ? true
      : false,
    getWindowSize: function getWindowSize() {
      afreshDOMInfo.winH = window.innerHeight;
      afreshDOMInfo.winW = window.innerWidth;
      afreshDOMInfo.adminBarHeight =
        $wpAdminBar.length > 0 ? $wpAdminBar.height() : 0;
    },
    scrollTop: 0,
    clientX: 0,
    clientY: 0,
    scrollPosMouse: function scrollPosMouse() {
      return $window.scrollTop();
    },
    bindEvents: function bindEvents() {
      if (!afreshDOMInfo.usingMobileBrowser) {
        $window.on("scroll", function () {
          afreshDOMInfo.scrollTop = afreshDOMInfo.scrollPosMouse();
        });
      } else {
        afreshDOMInfo.scrollTop = afreshDOMInfo.scrollPosMouse();
      }

      document.addEventListener("mousemove", function (e) {
        afreshDOMInfo.clientX = e.clientX;
        afreshDOMInfo.clientY = e.clientY;
      });
      $window.on("resize", afreshDOMInfo.getWindowSize);
    },
    init: function init() {
      // Re-cache WP admin bar after DOM ready
      $wpAdminBar = $("#wpadminbar"); // Get values and bind events

      this.getWindowSize();
      this.bindEvents();
    },
  };
  /**
   * Header layout "Centered logo between menu".
   *
   * Splits the main navigation menu into two sections placed on each side of logo.
   *
   * @since 8.0
   */

  function centeredLogoMargins() {
    if (afreshDOMInfo.winW >= 1000) {
      var $navItemLength = $(
          ".site-header .site-header-inner .site-navigation > li"
        ).length,
        $centerLogoWidth,
        $extraMenuSpace;

      if (
        $(".site-header-inner .row .header-col.col_3 #logo img:visible")
          .length == 0
      ) {
        $centerLogoWidth = parseInt(
          $(".site-header-inner .row .header-col.col_3").width()
        );
      } else {
        $centerLogoWidth = parseInt(
          $(".site-header-inner .row .header-col.col_3 img:visible").width()
        );
      }

      $extraMenuSpace = parseInt(
        $(".site-header .site-header-inner nav > ul > li:first-child > a").css(
          "padding-right"
        )
      );

      if ($extraMenuSpace > 30) {
        $extraMenuSpace += 35;
      } else if ($extraMenuSpace > 20) {
        $extraMenuSpace += 30;
      } else {
        $extraMenuSpace += 25;
      }

      $(
        ".site-header .site-header-inner .site-navigation > li:nth-child(" +
          Math.floor($navItemLength / 2) +
          ")"
      )
        .css({
          "margin-right": $centerLogoWidth + $extraMenuSpace + "px",
        })
        .addClass("menu-item-with-margin");
      var $leftMenuWidth = 0;
      var $rightMenuWidth = 0;
      $(".site-header .site-header-inner .site-navigation > li").each(function (
        i
      ) {
        if (i + 1 <= Math.floor($navItemLength / 2)) {
          $leftMenuWidth += $(this).width();
        } else {
          $rightMenuWidth += $(this).width();
        }
      });
      var $menuDiff = Math.abs($rightMenuWidth - $leftMenuWidth);
      $(".site-header-inner .row > .header-col.col_3 #logo").css(
        "margin-left",
        -($menuDiff / 2)
      );
    } else if (afreshDOMInfo.winW < 1000) {
      $(".site-header-inner .row > .header-col.col_3 #logo").css({
        "margin-left": "0",
      });
    }
  }
  /**
   * Header layout "Centered logo between menu" initialization only.
   *
   * @since 10.5
   */

  function centeredLogoHeaderInit() {
    centeredLogoMargins(); // Resize event.

    $window.on("resize", centeredLogoMargins);
  }

  function navToggle() {
    $(".side-navigation").on("click", ".dropdown > a", function (e) {
      e.preventDefault();
      $(this).parent().toggleClass("opened");
    });

    $(".side-navigation").on(
      "click",
      ".menu-item-has-children > a",
      function (e) {
        e.preventDefault();
        $(this).parent().toggleClass("opened");
      }
    );
  }

  function slideMenuInit() {
    var $endOffset =
      $wpAdminBar.css("position") == "fixed" ? $wpAdminBar.height() : 0;

    var $conPost = 0;
    var $headerScrollTop = afreshDOMInfo.scrollTop;

    if ($("body.mobile").length > 0) {
      var $eleHeight = 0;
      var $endOffset =
        $wpAdminBar.css("position") == "fixed" ? $wpAdminBar.height() : 0;
      $eleHeight += afreshDOMInfo.adminBarHeight;

      if ($eleHeight - $headerScrollTop > $endOffset) {
        $slideMenu.css("top", $eleHeight - $headerScrollTop + "px");
      } else {
        $slideMenu.css("top", $endOffset);
      } //    $slideMenu.css("top", $endOffset);
      //   $headerOuterEl.css("top", $endOffset);
    }

    if ($wpAdminBar.height() > 0) {
      $conPost = $topMenu.outerHeight();
    } else {
      $conPost = $topMenu.outerHeight() + $endOffset;

      if ($(".announcement-bar").length > 0) {
        $conPost = $topMenu.outerHeight() + $(".announcement-bar").height();
      }

      if ($(".announcement-bar").length > 0) {
        var $endOffset = $announceBar.height();
      }
    }

    if ($("body.shopify-theme").length > 0) {
      if ($(".announcement-bar").length > 0) {
        $conPost = $topMenu.outerHeight() + $(".announcement-bar").height();
      }
    }

    $mainContainer.css("top", $conPost);

    $topMenu.css("top", $endOffset);
  }

  function addOrRemoveSF() {
    if (afreshDOMInfo.winW < 1000) {
      $body.addClass("mobile");
      $(".site-header-inner nav").css("display", "none");
      slideMenuInit();
    } else {
      $body.removeClass("mobile");
      $(".site-header-inner nav").css("display", "");
      slideMenuInit();
      $(".menu-wrapper > .hamburger-menu").removeClass("animate");
    }
  }

  function openMenu() {
    $(".hamburger-menu").toggleClass("animate");

    if ($(".hamburger-menu.animate").length > 0) {
      $slideMenu
        .css({
          transform: "translate3d(0,0,0)",
        })
        .addClass("open");
    } else {
      $slideMenu
        .css({
          transform: "translate3d(-101%,0,0)",
        })
        .removeClass("open");
    }

    $html.toggleClass("slideOut");

    if ($("html.slideOut").length === 0) {
      $topMenu.parent().removeClass("search-active");
      $body.removeClass("search-active");
    }
  }

  function stickyHeader() {
    // Add class on scroll
    $window.on("scroll", function () {
      slideMenuInit();

      if ($window.scrollTop() >= 65) {
        $(".top-menu").addClass("sticky");
      } else {
        $(".top-menu").removeClass("sticky");
      }
    });
  }

  function searchToggle() {
    $topMenu.parent().toggleClass("search-active");
    $body.toggleClass("search-active");
  }

  function getCookieValue(a) {
    var b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }

  function cartUpdate(e) {
    var l = window.location.host;
    "shop.thewellnessrefinery.com" != l &&
      "thewellnessrefineryshop.myshopify.com" != l &&
      (function () {
        var t = window.location.host;

        let count = getCookieValue("twr_cart_item_count");
        let el = document.getElementById("cart-link");
        if (el) {
          $topMenu.find("#cart-link .count").text(count.toString());

          if (!!count && parseInt(count) !== 0) {
            $topMenu.find(".cart").removeClass("no-count");
            $topMenu.find("#cart-link .count").addClass("show");
          } else {
            $topMenu.find(".cart").addClass("no-count");
            $topMenu.find("#cart-link .count").removeClass("show");
          }
        }

        // if (
        //   "thewellnessrefinery.com" == t ||
        //   "shop.thewellnessrefinery.com" == t
        // )
        //   var n = "https://shop.thewellnessrefinery.com/cart.json";
        // else var n = "https://thewellnessrefineryshop.myshopify.com/cart.json";
        // e.ajax({
        //   url: n,
        //   method: "GET",
        //   dataType: "jsonp",
        //   success: function success(t) {
        //     var n = 0;
        //     e(t.items).each(function (e, t) {
        //       "Gift Message" != t.product_type && (n += t.quantity);
        //     }),
        //       n > 0
        //         ? $topMenu.find(".cart").removeClass("no-count")
        //         : $topMenu.find(".cart").addClass("no-count"),
        //       $topMenu.find("#cart-link .count").addClass("show").text([n]);
        //     console.log(n);
        //   },
        //   error: function error(e) {
        //     console.log(e);
        //     $topMenu.find(".cart").addClass("no-count");
        //   },
        // });
      })();
  }
  /**
   * Main resize event.
   *
   * @since 2.0
   */

  function resizeInit() {
    addOrRemoveSF();

    if ($(".slideOut").length > 0) {
      $(".hamburger-menu").addClass("animate");
    } else {
      $(".hamburger-menu").removeClass("animate");
    }
  }

  jQuery(document).ready(function (e) {
    $("body").addClass("loaded"); // Initialize  DOM helper.
    afreshDOMInfo.init();
    addOrRemoveSF();
    stickyHeader();
    navToggle();
    cartUpdate(e);
    //  centeredLogoHeaderInit();
    $topMenu.on("click", ".search-toggle", searchToggle);
    $body.on("click", ".search-overlay", searchToggle);
    $body.on("click", ".menu-wrapper", openMenu);
    $window.on("resize", resizeInit);

    $(".nav-item.dropdown > .shop-mega-menu .dropdown-list > li > a").click(
      function (e) {
        e.preventDefault();
      }
    );
  });
})(jQuery, window, document);
